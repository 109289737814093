import * as React from 'react'

import getConfig from 'next/config'

import { CmsComponentSlot, CmsHeadTags } from '@grandvisionhq/bloomreach'
import { Button } from '@grandvisionhq/common'
import { Container } from '@grandvisionhq/elements'
import { NextPage } from '@grandvisionhq/next'
import { useIntl } from '@grandvisionhq/state'
import { monetate } from '@grandvisionhq/svc-monetate'
import { compose } from '@grandvisionhq/utils'

import { withCms, withLayout } from '../cms'

const { publicRuntimeConfig } = getConfig()
const gatUrl = publicRuntimeConfig.grandvision.gatUrl || ''

type InitialProps = {
  queryParams?: Record<string, string | string[] | undefined>
}

type Props = InitialProps

const getIFrameUrl = (locale: string, storeId: string) => {
  return `${gatUrl}/${locale}/Start?spx.StartPageIndex=2&ftr.6.CommentsFilter.DefaultValue=&ftr.6.TextFilter.Visible=true&ftr.7.FacilityFilter.Visible=false&ftr.8.FacilityFilter.Visible=true&ftr.8.FacilityFilter.Reference=F${storeId}&fsversion=0`
}

const AppointmentPage: NextPage<Props, InitialProps> = ({ queryParams = {} }) => {
  const { getLabel, locale } = useIntl()
  const { storeId } = queryParams
  const isValidStoreId = typeof storeId === 'string' && /[0-9]{3}/.test(storeId)
  const url = typeof storeId === 'string' ? getIFrameUrl(locale, storeId) : ''

  if (!isValidStoreId) {
    // tslint:disable-next-line:no-console
    console.error(`Error: storeId is not valid. Store Id "${storeId}" should be numeric`)
  }

  React.useEffect(() => {
    monetate().commitMonetateEvent('appointments')
  }, [])

  return (
    <>
      <CmsHeadTags
        title={getLabel('appointment.booking.page.title')}
        noIndex={true}
        noFollow={true}
      />
      <CmsComponentSlot path="main/top-container" />
      <Container className="appointment-layout">
        <h1>{getLabel('appointment.booking.page.title')}</h1>
        {url && isValidStoreId ? (
          <iframe
            src={url}
            className="iframe appointment__gat-iframe"
            id="gat-tool"
            title="Appointment"
          />
        ) : (
          <div className="appointment__error">
            <h3>{getLabel('appointment.errors.generic.message')}</h3>
            <Button
              caption={getLabel('appointment.actions.goBack')}
              color="secondary"
              href="javascript:window.history.go(-1)"
            />
          </div>
        )}
      </Container>
      <CmsComponentSlot path="main/bottom-container" />
    </>
  )
}

AppointmentPage.getInitialProps = ({ query }) => ({
  queryParams: query,
})

export default compose<Props, Props>(withCms(), withLayout())(AppointmentPage)
